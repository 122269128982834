import * as React from "react"
import LogoWhite from "../images/runic-logo.svg"
import MailIcon from "../images/mail-icon.svg"
import Obfuscate from 'react-obfuscate';
import Header from "../component/layout/header";
import Charts from "../images/charts.svg"
import BullHorn from "../images/bullhorn.svg"
import Reporting from "../images/reporting.svg"
import {AnchorLink} from "gatsby-plugin-anchor-links";


const InputClass = "w-full bg-pop-bg text-pop-stone border hover:border-pop-aqua rounded-md border-green-800 focus:border-pop-aqua focus:ring-2 focus:ring-pop-aqua"
const LabelClass = "block pb-2 font-light"
const LinkClass = "text-pop-stone xs:p-1 pr-2 md:p-4 hover:text-pop-aqua font-light"
const ContactButtonClass = "sm:text-lg text-base font-extralight text-pop-stone bg-pop-blue sm:py-4 sm:px-6 py-3 px-4 rounded-full hover:bg-blue-800 hover:text-white"

const IndexPage = () => {
    return (
        <>
            <div className={"container mx-auto"}>
                <Header />
            </div>
            <div className={"lg:container lg:mx-auto lg:px-8 2xl:px-0 xl:px-12 px-0"}>
                <div className={"lg:grid lg:grid-cols-2 lg:pb-40 sm:pb-24 pb-12"}>
                    <div className={"xl:pt-16 lg:pt-28 xl:pl-6 lg:not-sr-only sr-only"}>
                        <Charts className={"2xl:w-[800px] xl:w-[700px] lg:w-[560px] lg:not-sr-only sr-only"}/>
                    </div>
                    <div className={"text-center text-pop-stone"}>
                        <div
                            className={"2xl:text-5xl xl:text-4xl lg:text-3xl md:text-4xl sm:text-4xl xs:text-2xl text-md text-pop-stone font-bold pb-6 lg:pt-32 pt-16"}>
                            <div className={"xl:pb-12 lg:pb-8 md:pb-12 sm:pb-8 pb-4"}><span
                                className={"bg-pop-blue text-white xl:px-8 lg:px-6 md:px-8 px-6 xl:py-2 md:py-2 lg:py-1 py-1 rounded-full"}>Marketing Intelligence</span>
                            </div>
                            <div>Re-Imagined</div>
                        </div>
                        <div
                            className={"font-thin xl:text-2xl lg:text-xl md:text-2xl sm:text-2xl xs:text-lg text-sm sm:pb-16 pb-8"}>
                            Runic uses cutting-edge AI<br/>
                            to help marketing teams<br/>
                            understand customer feedback<br/>
                            and make data-driven decisions
                        </div>
                        <div className={"lg:sr-only text-center"}>
                            <Charts
                                className={"inline sm:w-[400px] md:w-[500px] max-w-full lg:sr-only md:-mt-24 -mt-16 overflow-hidden"}/>
                        </div>
                        <div className={"xl:text-3xl lg:text-2xl md:text-3xl sm:text-3xl text-xl font-medium pb-6"}>
                            <span className={"text-pop-aqua"}>Information has Changed</span><br/>
                        </div>
                        <div className={"font-thin xl:text-2xl lg:text-xl md:text-2xl sm:text-xl xs:text-lg text-sm"}>
                            Why do we analyze it the same way?
                        </div>
                    </div>
                </div>
                <div id={"services"}
                     className={"text-center lg:text-2xl md:text-xl sm:text-lg xs:text-base text-sm sm:pb-16 pb-8"}>
                    <span className={"font-light tracking-[.9em] text-pop-aqua"}>OUR SERVICES</span>
                </div>
            </div>
            <div className={"lg:container lg:mx-auto lg:px-8 2xl:px-0 xl:px-12 px-0"}>
                <div className={"bg-pop-aqua lg:rounded-3xl text-pop-black 2xl:mb-48 lg:mb-24 mb-16"}>
                    <div
                        className={"lg:grid lg:grid-cols-2 xl:pt-24 xl:pb-32 2xl:px-32 xl:px-28 sm:p-8 xs:p-4 lg:text-left text-center"}>
                        <div>
                            <div
                                className={"xl:text-5xl lg:text-4xl sm:text-5xl xs:text-3xl text-2xl font-medium lg:pb-12 pb-4 sm:pt-0 pt-4"}>
                                <div className={"sm:pb-4 pb-2"}>Supercharged</div>
                                <div>Analytics Hub</div>
                            </div>
                            <div className={"lg:sr-only text-center"}>
                                <BullHorn
                                    className={"inline sm:w-[400px] md:w-[500px] max-w-full lg:sr-only overflow-hidden"}/>
                            </div>
                            <div
                                className={"font-extralight xl:text-xl lg:text-lg md:text-2xl sm:text-lg max-[400px]:text-base text-md leading-relaxed tracking-wider"}>
                                Runic uses AI to analyze unstructured <br />
                                content. Say goodbye to manually<br/>
                                cleaning datasets.
                            </div>
                            <div className={"sm:pt-12 pt-10 mb-6"}>
                                <AnchorLink className={ContactButtonClass} to={"/#contact-form"} title={"Contact Us"}/>
                            </div>
                        </div>
                        <div>
                            <BullHorn className={"2xl:w-[700px] xl:w-[600px] lg:w-[450px] lg:not-sr-only sr-only"}/>
                        </div>
                    </div>
                    <div
                        className={"bg-white lg:rounded-3xl lg:-rotate-2 xl:py-28 xl:px-32 lg:-ml-6 lg:-mr-6 lg:p-16 sm:p-12 py-8 px-2 text-center lg:text-left"}>
                        <div className={"lg:rotate-2 lg:flex lg:flex-row"}>
                            <div
                                className={"lg:basis-1/3 md:text-4xl sm:text-4xl xs:text-4xl text-4xl lg:text-3xl xl:text-4xl font-medium lg:pb-1 pb-8 pt-0 xl:pt-0 lg:pt-8"}>
                                <div className={"sm:pb-5 pb-3 lg:pl-6"}>Actionable</div>
                                <div className={"sm:pb-5 pb-3"}>
                                    <span className={"px-6 bg-pop-aqua rounded-full max-[420px]:text-3xl max-[350px]:text-2xl"}>
                                        Recommendations
                                    </span>
                                </div>
                            </div>
                            <div
                                className={"lg:pl-4 pl-0 pt-0 lg:pt-8 xl:pt-0 lg:basis-2/3 font-extralight xl:text-2xl 2xl:text-2xl lg:text-lg md:text-xl sm:text-lg min-[470px]:text-lg max-[400px]:text-base text-lg leading-relaxed tracking-wider"}
                            >
                                Our AI combines insight with your goals<br />
                                to create relevant recommendations.<br />
                                Want copy suggestions? No problem.<br />
                                Content strategy? We’ve got you.
                            </div>
                        </div>
                    </div>
                    <div className={"lg:pt-28 xl:pb-32 lg:pb-24 lg:px-0 sm:p-12 py-12 px-2 lg:text-left text-center"}>
                        <div className={"lg:grid lg:grid-cols-2"}>
                            <div className={"text-center xl:pt-0 lg:pt-8 pt-0"}>
                                <Reporting
                                    className={"2xl:w-[700px] xl:w-[550px] lg:w-[420px] lg:not-sr-only sr-only inline"}/>
                            </div>
                            <div className={"lg:pl-12"}>
                                <div className={"xl:text-6xl lg:text-5xl sm:text-6xl xs:text-4xl text-3xl font-medium pb-8"}>
                                    <div>Instant</div>
                                    <div>Reporting</div>
                                </div>
                                <div className={"text-center lg:sr-only sm:p-0 px-6 py-4"}>
                                    <Reporting
                                        className={"inline sm:w-[400px] md:w-[500px] max-w-full lg:sr-only overflow-hidden pb-6"}/>
                                </div>
                                <div
                                    className={"font-extralight xl:text-2xl lg:text-xl md:text-2xl min-[470px]:text-lg max-[400px]:text-base text-lg leading-relaxed tracking-wider"}>
                                    Rapidly generate custom reports,<br/>
                                    complete with summaries, insights,<br />
                                    and personalized recommendations.
                                </div>
                                <div className={"pt-12"}>
                                    <AnchorLink className={ContactButtonClass} to={"/#contact-form"} title={"Contact Us"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"lg:container lg:mx-auto lg:px-8 2xl:px-0 xl:px-12 px-0"}>
                <div className={"pt-12"}>
                    <div className={"text-center lg:text-2xl md:text-xl sm:text-lg xs:text-base text-sm sm:pb-16 pb-12"}>
                        <span className={"font-light tracking-[.9em] text-pop-aqua"}>GET IN TOUCH</span>
                    </div>
                </div>
                <div className={"bg-pop-black lg:rounded-3xl text-pop-stone xl:p-24 sm:p-12 p-8 lg:flex lg:flex-row"}>
                    <div className={"lg:basis-1/3 text-center lg:not-sr-only sr-only"}>
                        <div className={"xl:pr-20 lg:pr-12"}>
                            <LogoWhite className={"w-[180px] inline"}/>
                            <div className={"w-[60px] pt-8 border-b border-white mx-auto"}></div>
                            <div className={"pt-8"}>
                                <MailIcon className={"w-[29px] inline"}/><span
                                className={"font-light pl-4"}><Obfuscate email="outreach@runic.ai"/></span>
                            </div>
                        </div>
                    </div>
                    <div id={"contact-form"} className={"lg:basis-2/3 xl:pr-24 lg:pr-12"}>
                        <form method="POST" action="/success" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                            <input type="hidden" name="bot-field"/>
                            <input type="hidden" name="form-name" value="contact"/>
                            <div className={"lg:grid lg:grid-cols-2 lg:gap-8 pb-8"}>
                                <div className={"lg:pb-0 pb-8"}>
                                    <label>
                                        <span className={LabelClass}>First name</span>
                                        <input className={InputClass} type="text" name={"firstname"}/>
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <span className={LabelClass}>Last name</span>
                                        <input className={InputClass} type="text" name={"lastname"}/>
                                    </label>
                                </div>
                            </div>
                            <div className={"pb-8"}>
                                <label>
                                    <span className={LabelClass}>Email</span>
                                    <input className={InputClass} type={"email"} name={"email"}/>
                                </label>
                            </div>
                            <div className={"pb-8"}>
                                <label>
                                    <span className={LabelClass}>Message</span>
                                    <input className={InputClass} type="text" name={"message"}/>
                                </label>
                            </div>
                            <button type={"submit"}
                                    className={"font-extralight text-pop-stone bg-pop-blue py-2 mt-2 sm:px-12 px-6 rounded-full hover:bg-blue-800 hover:text-white"}>
                                Send
                            </button>
                        </form>
                        <div className={"lg:sr-only pt-12"}>
                            <MailIcon className={"w-[29px] inline"}/><span
                            className={"font-light pl-4 xs:text-base text-sm"}>
                            <Obfuscate
                                email="outreach@runic.ai"
                            />
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className={"lg:pt-40 pt-12 text-pop-stone font-extralight sm:text-sm text-xs sm:px-12 px-4 lg:px-0"}>
                    <LogoWhite className={"sm:w-[144px] w-[100px]"}/>
                    <div className={"md:grid md:grid-cols-2 flex pt-12 pb-6"}>
                        <div className={"grow sm:pt-0 pt-1"}>
                            <div>
                          <span>
                              Copyright 2023 Runic. All Rights Reserved.
                          </span>
                            </div>
                        </div>
                        <div className={"text-right"}>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const Head = () => (
    <>
        <title>Runic AI</title>
    </>
)

export default IndexPage
