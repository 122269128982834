import LogoWhite from "../../images/runic-logo.svg";
import {Link} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import * as React from "react";
import {LinkClass} from "./ClassConstants";

const Header = () => {
    return (
        <header className="xs:flex px-1 md:px-6 md:pt-6 pt-3">
            <div className={"xs:grow"}>
                <LogoWhite className={"md:w-[170px] xs:w-[120px] w-[100px]"}/>
            </div>
            <div>
                <div className={"text-white sm:pt-2 xs:pt-1 pt-2 sm:text-base text-sm"}>
                    <Link className={LinkClass} to={"/"}>Home</Link>
                    <AnchorLink className={LinkClass} to={"/#contact-form"} title={"Contact"}/>
                </div>
            </div>
        </header>
    )
}

export default Header;